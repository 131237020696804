const pkg =require('../../package.json');

export const environment = {
  appName: "Tieple Company Portal",
  appVersion: `${pkg.version}-Local`,
  firebase: {
    apiKey: "AIzaSyDlfMP016YXK-lE2kLIS9wabM9RS2QmcX0",
    authDomain: "tieple-dev.firebaseapp.com",
    databaseURL: "https://tieple-dev.firebaseio.com",
    projectId: "tieple-dev",
    storageBucket: "tieple-dev.appspot.com",
    messagingSenderId: "1001605401012",
    appId: "1:1001605401012:web:bccbf40e1e9125bd2d4ae1",
    measurementId: "G-Q4M3D4RWGM"
  },
  isCloudDeployment: false,
  isDevelopEnv: true,
  isProductionBuild: false,
  isTestEnv: false
};
