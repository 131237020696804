import { Component, OnInit } from '@angular/core';
import { sidebarMenuItems } from '@app/constants/sidebarMenuItems';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  isMenuOpen: boolean = false;
  menuItems: { key: number, title: string, link: string, imagePath: string }[] = sidebarMenuItems;
  constructor() { }

  ngOnInit(): void {
  }
  menuAction() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
