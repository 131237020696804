<div *ngIf="!isMenuOpen" class='app-navbar'>
    <div class='app-navbar__mobile-sidebar-menu' (click)="menuAction()">
      <img alt="menu" class='app-navbar__mobile-sidebar-menu-img' src="../../../../../../assets/icons/SidebarMenuIconSmall.png"/>
    </div>
    <div class='app-navbar__logo' [routerLink]="['']">
      <img alt="logo" class='app-navbar__logo-img' src="../../../../assets/images/tieple_logo.png"/>
      <div class='app-navbar__logo-name'>tieple</div>
    </div>

    <div class='app-navbar__right-menu'>
      <span [routerLink]="['schedule-interview/CAD001']" class='app-navbar__menu'>Schedule Interview</span>
      <span [routerLink]="['search']" class='app-navbar__menu'>Search for Candidates</span>
      <span [routerLink]="['search/shortlisted-candidates']" class='app-navbar__menu'>Shortlisted Candidates</span>
     
  
    <div class='app-navbar__mobile-right-menu'>
      <img alt="menu" class='app-navbar__mobile-right-menu-img' src="../../../../../../assets/icons/LogoutIcon.png"/>
    </div>
  </div>
  <div *ngIf="isMenuOpen" class='app-navbar__mobile-sidebar-menu-container'>
    <div class='app-navbar__mobile-sidebar-menu-container-close' (click)="menuAction()">X</div>
    <div class='app-navbar__mobile-sidebar-menu-container-title'>Main Menu</div>
    <div class='app-navbar__mobile-sidebar-menu-container-seperator'></div>
    <div class='app-navbar__mobile-sidebar-menu-container-items'>
      <div class='app-navbar__mobile-sidebar-menu-container-item' *ngFor="let item of menuItems">
        <img class='app-navbar__mobile-sidebar-menu-container-item-img' [src]="item.imagePath" alt="icon">
        <span class='app-navbar__mobile-sidebar-menu-container-item-name' (click)="menuAction()" [routerLink]="[item.link]">{{item.title}}</span>
      </div>
    </div>
    <div class='app-navbar__mobile-sidebar-menu-container-footer'>Copyright 2021. All Rights Reserved.</div>
  
  </div>
  