import { NgModule } from '@angular/core';
import { IvNxtRoutes, VNxtCanActivateGuard } from "@vnxt/angular-auth";
import { RouterModule } from '@angular/router';
import { CommonContentLayoutComponent } from './shared/components/common-content-layout/common-content-layout.component';


const routes: IvNxtRoutes = [
  {
    path: '',
    component: CommonContentLayoutComponent,
    canActivate: [VNxtCanActivateGuard], // Should be replaced with actual auth guard,
    data: {
      Title: 'Title',
      Breadcrumb: 'Title',
      accessPath: []
    },
    children: [
      {
        // Fallback when no prior routes is matched
        path: 'auth',
        loadChildren: () =>
          import('./modules/auth/auth.module').then(m => m.AuthModule)
      },
      {
        // Fallback when no prior routes is matched
        path: 'search',
        loadChildren: () =>
          import('./modules/candidates/candidate.module').then(m => m.CandidateModule)
      },
      {
        path: 'schedule-interview/:candiateId',
        loadChildren: () =>
          import('@modules/interview/interview.module').then(m => m.InterviewModule)
      },
      {
        // Fallback when no prior routes is matched
        path: '**',
        loadChildren: () =>
          import('./modules/home/home.module').then(m => m.HomeModule)
      }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
