<div class="banner">
    <input class="banner__input" matInput placeholder="Search technology, stack, concepts, etc.">
    <div class="banner__buttons">
        <button class="secondary-action-btn">
            Shortlisted Profiles
        </button>
        <button class="primary-action-btn">
            Interviews
        </button>
    </div>
</div>
