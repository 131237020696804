import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-content-layout',
  templateUrl: './common-content-layout.component.html',
  styleUrls: ['./common-content-layout.component.scss']
})
export class CommonContentLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
