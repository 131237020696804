import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AngularSupportModule} from "@vnxt/angular-support";
import {ISupportEnvironmentOptions} from "@vnxt/angular-support/lib/interface/common";
import {environment} from "../../environments/environment";
import {IAuthEnvironmentOptions, VNxtAuthModule} from "@vnxt/angular-auth";
import { NavComponent } from './components/nav/nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { CommonContentLayoutComponent } from './components/common-content-layout/common-content-layout.component';
import { CommonBannerComponent } from './components/common-banner/common-banner.component';


@NgModule({
  declarations: [
      NavComponent,
      FooterComponent,
      CommonContentLayoutComponent,
      CommonBannerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AngularSupportModule.initializeAppModule(environment as ISupportEnvironmentOptions),
    VNxtAuthModule.initializeAppModule(environment as IAuthEnvironmentOptions),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AngularSupportModule,
    VNxtAuthModule,
    CommonBannerComponent
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
}
