import { NgModule } from '@angular/core';
import { provideFirebaseApp } from '@angular/fire/app';
import { ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { PerformanceMonitoringService } from '@angular/fire/compat/performance';
import { BrowserModule } from '@angular/platform-browser';
import { VNxtAuthService, VNxtCanActivateChildGuard, VNxtCanActivateGuard, VNxtCanLoadGuard, VNxtRouteService } from '@vnxt/angular-auth';
import { initializeApp } from 'firebase/app';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { environment } from 'environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    BrowserAnimationsModule
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    PerformanceMonitoringService,
    VNxtRouteService,
    VNxtAuthService,
    VNxtCanLoadGuard,
    VNxtCanActivateGuard,
    VNxtCanActivateChildGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
