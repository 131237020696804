<div>
    <div class="mat-app-background">
      <app-nav class="app-navbar"></app-nav>
      <div class="tp-container">
        <div class="content-scroll-area">
          <router-outlet></router-outlet>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>